<template>
    <b-tab v-if="can('forum-channels')" title="Channels">
        <div class="mb-3" id="review-workflow">
            <label>Channel Name <span style="color: red;">*</span></label>
            <b-form-checkbox-group
                v-model="selectedChannels"
                :options="optionsChannels"
                value-field="value"
                text-field="text"
            >
            </b-form-checkbox-group>
        </div>
        <b-button v-if="can('update-forum-channels')" type="submit" variant="primary" class="mr-2" @click.prevent="updateChannelData($route.params.id)">Update Data</b-button>
    </b-tab>
</template>

<script>
import MixinRequest from "../../../../mixins/request";
import forumToolChannelMixin from "../../../../mixins/ModuleJs/forum-channel";

export default {
    mixins: [MixinRequest, forumToolChannelMixin],
    data() {
        return {
            selectedChannels: [],
            optionsChannels: [], 
        };
    },
};
</script>