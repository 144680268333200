<template>
   <b-tab v-if="can('brand-stages')" title="Reviewer Workflow Stages">
      <div class="mb-3">
         <label>Reviewer Workflow Stages <span style="color: red;">*</span></label>
         <template v-if="review_stage_response.length == 0">
            <div style="height: 200px; width: 100%; font-size: 24px; font-weight: 700"
               class="d-flex justify-content-center align-items-center">
               <div style="display: grid; justify-items: center; grid-gap: 10px">
                  <img src="../../../../../public/noData.svg" alt="" />
                  <span style="font-size: 20px; font-weight: 700">No Stages Available</span>
                  <router-link :to="{ name: 'add-stage' }">
                     <b-button type="button" variant="primary" class="btn-sm">Add Stage</b-button>
                  </router-link>
               </div>
            </div>
         </template>
         <template v-else>
            <div class="d-flex" style="grid-gap: 10px" id="review-workflow">
               <b-form-checkbox-group v-model="form.stage_id" :options="review_stage_response" value-field="id"
                  text-field="name">
               </b-form-checkbox-group>
            </div>
            <b-button v-if="can('add-update-brand-stage')" type="submit" variant="primary" class="mr-2 mt-4 btn-md"
               @click.prevent="updateBrandStage">Update Data</b-button>
         </template>
      </div>
   </b-tab>
</template>

<script>
import MixinRequest from "../../../../mixins/request";
import brandStageMixin from "../../../../mixins/ModuleJs/brand-stage";

export default {
   mixins: [MixinRequest, brandStageMixin],
};
</script>