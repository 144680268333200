import {
    forum_tool,
  } from "../../js/path";
  
export default {
    data() {
      return {
        activeTab: "all",
        submitted: false,
        table_header: [],
        edit_form_id : null,
        key : 0,
        forum_id: "",
        forum_tool_id: "",
        form: {
          api_key: "",
          api_secret: "",
          api_url: "",
          vendor_name: "",
          channel_type: null,
        },
        channelType: [
            {
              value: null,
              title: 'Enter Channel Type',
              disabled: true,
            },
            {
              value: 'sms',
              title: 'SMS'
            },
            {
              value: 'whatsapp',
              title: 'Whatsapp'
            },
            {
              value: 'email',
              title: 'Email'
            }
        ],
      }
    },
    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async submitVendorData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields"
            });
            return false;
          }
          let url = forum_tool.forumToolVendors;
          let dataAppend = new FormData();
          dataAppend.append("forum_id", this.$route.params.forum_id);
          dataAppend.append("forum_tool_id", this.$route.params.id);

          for (var key in this.form) {
              dataAppend.append(key, this.form[key]);
          }
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
            });
            this.$router.push("/forum-settings/" + this.$route.params.id + "/" + this.$route.params.forum_id);
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async editvendor() {
        try {
          const url =  forum_tool.forumToolVendors + "/" + this.$route.params.id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            if (responseData.forum_id != "" && responseData.forum_id != null) {
              this.forum_id = responseData.forum_id;
            }
            if (responseData.forum_tool_id != "" && responseData.forum_tool_id != null) {
              this.forum_tool_id = responseData.forum_tool_id;
            }
            if (responseData.vendor_name != "" && responseData.vendor_name != null) {
              this.form.vendor_name = responseData.vendor_name;
            }
            if (responseData.channel_type != "" && responseData.channel_type != null) {
              this.form.channel_type = responseData.channel_type;
            }
            if (responseData.api_key != "" && responseData.api_key != null) {
              this.form.api_key = responseData.api_key;
            }
            if (responseData.api_secret != "" && responseData.api_secret != null) {
              this.form.api_secret = responseData.api_secret;
            }
            if (responseData.api_url != "" && responseData.api_url != null) {
              this.form.api_url = responseData.api_url;
            }
          }
        } catch (err) {
          console.log(err);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: err.data ? err.data.message : "Please try again!",
            });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async deleteVendor(id) {
          this.$store.commit("loader/updateStatus", true);
          try {
            let index = this.tableData.data.findIndex((e) => e.id === id);
            const url = forum_tool.forumToolVendors + "/" + id    ;
            const data = await this.postRequest(url, {
              _method: "DELETE",
            });
            if (data.status) {
              this.tableData.data.splice(index, 1);
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: data.message,
              });
            }
          } catch (err) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: err.data ? err.data.message : "Please try again!",
            });
          }
          this.$store.commit("loader/updateStatus", false);
      },
      async resotreVendor(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = forum_tool.restoreVendor + "/" + id;
          const data = await this.postRequest(url);
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async updateVendor() {
        try {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: "Please Fill The Required Fields"
              });
              return false;
            }
            let url = forum_tool.forumToolVendors + "/" + this.$route.params.id;
            let dataAppend = new FormData();
            dataAppend.append("forum_id", this.forum_id);
            dataAppend.append("forum_tool_id", this.forum_tool_id);
            for (var key in this.form) {
              dataAppend.append(key, this.form[key]);
            }
            dataAppend.append("_method", "put");
            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
                this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: data.message,
                });
                this.$router.push("/forum-settings/" + this.forum_tool_id + "/" + this.forum_id);
            }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async fetchData(txt) {
        this.$store.commit("loader/updateStatus", true);
        let url = null;
        try {
          if (txt == "trash") {
            this.activeTab = txt;
            url = forum_tool.fetchVendorList + "/" + this.$route.params.forum_id + "?filter=" + txt;
          } else if (txt == "all") {
            this.activeTab = txt;
            url = forum_tool.fetchVendorList + "/" + this.$route.params.forum_id + "?filter=" + txt;
          }
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      tabActive() {
        if (this.activeTab == "trash") {
          if (this.table_header.includes("delete")) {
            let index = this.fields.findIndex((item) => item.key == "delete");
            this.fields[index] = {
              key: "restore",
            };
            let table_index = this.table_header.findIndex((item) => item == "delete");
            this.table_header[table_index] = "restore";
  
          } else {
            this.table_header.push("restore");
            this.fields.push({
              key: "restore",
            });
          }
          if (!this.can("restore-forum-vendor")) {
            let index = this.fields.findIndex((item) => item.key == "restore");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "restore"
              );
              this.table_header.splice(table_index, 1);
            }
          }
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (this.table_header.includes("restore")) {
            if (!this.can("edit-forum-vendor")) {
              this.fields[6] = {
                key: "delete",
              };
            } else {
              this.fields[7] = {
                key: "delete",
              };
            }
  
            let table_index = this.table_header.findIndex((item) => item == "restore");
            this.table_header[table_index] = "delete";
          }
          if (!this.can("edit-forum-vendor")) {
            let index = this.fields.findIndex((item) => item.key == "edit");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "edit"
              );
              this.table_header.splice(table_index, 1);
            }
          } else {
            if (!this.table_header.includes("edit")) {
              this.table_header.push("edit");
              this.fields[6] = {
                key: "edit",
              };
            }
          }
          if (!this.can("delete-forum-vendor")) {
            let index = this.fields.findIndex((item) => item.key == "delete");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "delete"
              );
              this.table_header.splice(table_index, 1);
            }
          } else {
            if (!this.table_header.includes("delete")) {
              this.table_header.push("delete");
              this.fields[7] = {
                key: "delete",
              };
            }
          }
        }
      }
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
      activeTab(v) {
        if (v) this.tabActive();
      },
    },
    mounted(){
      if (this.$route.name == "add-vendor" || this.$route.name == "edit-vendor") {
        if(this.$route.name == "edit-vendor"){
          this.$nextTick(() => {
            this.editvendor();
          }) 
        }
      } else {
        this.fetchData(this.activeTab);
        this.fields.map((item) => {
          this.table_header.push(item.key);
        });
        this.tabActive();
      }
    }
}