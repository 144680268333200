<template>
    <Layout>
        <PageHeader :title="title" :items="items"></PageHeader>
        <div class="row" id="forum">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-tabs content-class="mt-3">
                            <ForumVendors></ForumVendors>
                            <ForumLicenses></ForumLicenses>
                            <ChannelSelect></ChannelSelect>
                            <BrandStage></BrandStage>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import forumToolMixin from "../../../../mixins/ModuleJs/forum-tool";
// import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import ForumVendors from "./forumVendors.vue";
import ForumLicenses from "./forumLicenses.vue";
import ChannelSelect from "./channelSelect.vue";
import BrandStage from "./brandStage.vue";

export default {
    mixins: [MixinRequest, forumToolMixin],
    data() {
        return {
            submitted: false,
            title: "Settings",
            items: [
                {
                text: "Back",
                href: "/forum-tool",
                },
                {
                text: "Data",
                },
            ],
        };
    },
    validations: {
        form: {
        forum_list: { required },
        domain_name: { required },
        forum_logo: { required },
        forum_banner_image: { required },
        },
    },
    components: {
        Layout,         
        PageHeader,
        ForumVendors,
        ForumLicenses,
        ChannelSelect,
        BrandStage,
        // Multiselect,
    },
};
</script>