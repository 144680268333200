import {
    forum_tool,
    role
  } from "../../js/path";
  
export default {
    data() {
      return {
        activeTab: "all",
        submitted: false,
        table_header: [],
        key : 0,
        forum_id: "",
        forum_tool_id: "",
        form: {
          email: "",
          password: "",
          is_active: 0,
          role_id: ""
        },
        channelType: [
          {
            value: 'sms',
            title: 'SMS'
          },
          {
            value: 'whatsapp',
            title: 'Whatsapp'
          },
          {
            value: 'email',
            title: 'Email'
          }
        ],
        getForumRole: [],
      }
    },
    methods: {
      async fetchForumRoles() {
        try {
          const url = role.getForumRoles + "/" + this.$route.params.forum_id;
          const data = await this.getRequest(url);
          if (data.status) {
            this.getForumRole = data.response;
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please try again!"
          });
        }
      },
      onLicenseChange() {
        this.fetchData(this.activeTab);
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async submitLicenseData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields"
            });
            return false;
          }
          let url = forum_tool.forumToolLicenses;
          let dataAppend = new FormData();
          dataAppend.append("forum_id", this.$route.params.forum_id);
          dataAppend.append("forum_tool_id", this.$route.params.id);
          dataAppend.append("role_id", this.form.role_id);
          if (this.form.is_active) {
            dataAppend.append('is_active', 1);
          } else {
            dataAppend.append('is_active', 0);
          }
          for (var key in this.form) {
            if (key != 'is_active' && key != "role_id") {
              dataAppend.append(key, this.form[key]);
            }
          }
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
            });
            this.$router.push("/forum-settings/" + this.$route.params.id + "/" + this.$route.params.forum_id);
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async editLicenses() {
        this.$store.commit("loader/updateStatus", true);
        try {
          const url =  forum_tool.forumToolLicenses + "/" + this.$route.params.id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            if (responseData.forum_id != "" && responseData.forum_id != null) {
              this.forum_id = responseData.forum_id;
            }
            if (responseData.forum_tool_id != "" && responseData.forum_tool_id != null) {
              this.forum_tool_id = responseData.forum_tool_id;
            }
            if (responseData.email != "" && responseData.email != null) {
              this.form.email = responseData.email;
            }
            if (responseData.is_active != "" && responseData.is_active != null) {
              this.form.is_active = responseData.is_active;
            }
            if (responseData.role_id != "" && responseData.role_id != null) {
              this.form.role_id = responseData.role_id;
            }
          }
        } catch (err) {
          console.log(err);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: err.data ? err.data.message : "Please try again!",
            });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async deleteLicenses(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = forum_tool.forumToolLicenses + "/" + id    ;
          const data = await this.postRequest(url, {
            _method: "DELETE",
          });
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async resotreLicenses(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = forum_tool.restoreLicense + "/" + id;
          const data = await this.postRequest(url);
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async updateLicense(id = null,only_status = false) {
          try {
            this.submitted = true;
            if(only_status == false){
              this.$v.$touch();
              if (this.$v.$error) {
                if(this.$route.name == 'add-license' || (this.$route.name == 'edit-license' && this.$v.form.email.$error)){
                  this.$store.commit("toast/updateStatus", {
                    status: true,
                    icon: "error",
                    title: "Please Fill The Required Fields"
                  });
                  return false;
                }
              }
            }
            let param_id = this.$route.params.id;
            if(id != null && only_status == true){
              param_id = id;
              this.form = this.tableData.data.filter(t => t.id == id)[0];
              this.form.is_active = !this.form.is_active;
            }
            let url = forum_tool.forumToolLicenses + "/" + param_id;
            let dataAppend = new FormData();
            dataAppend.append("forum_id", this.forum_id);
            dataAppend.append("forum_tool_id", this.forum_tool_id);
            dataAppend.append("role_id", this.form.role_id);
            if (this.form.is_active) {
              dataAppend.append('is_active', 1);
            } else {
              dataAppend.append('is_active', 0);
            }
            for (var key in this.form) {
              if (key != 'is_active' && key != "role_id")  {
                dataAppend.append(key, this.form[key]);
              }
            }
            dataAppend.append("_method", "put");
            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
              this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
              });
              if(only_status == false){
                this.$router.push("/forum-settings/" + this.forum_tool_id + "/" + this.forum_id);
              }
            }
          } catch (err) {
            console.log(err);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: err.data ? err.data.message : "Please try again!",
            });
          }
          this.$store.commit("loader/updateStatus", false);
      },
      async fetchData(txt) {
        this.$store.commit("loader/updateStatus", true);
        let url = null;
        try {
            if (txt == "trash") {
              this.activeTab = txt;
              url = forum_tool.fetchLicenseList + "/" + this.$route.params.forum_id + "?filter=" + txt;
            } else if (txt == "all") {
              this.activeTab = txt;
              url = forum_tool.fetchLicenseList + "/" + this.$route.params.forum_id + "?filter=" + txt;
            }
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      tabActive() {
        if (this.activeTab == "trash") {
          if (this.table_header.includes("delete")) {
            let index = this.fields.findIndex((item) => item.key == "delete");
            this.fields[index] = {
              key: "restore",
            };
            let table_index = this.table_header.findIndex((item) => item == "delete");
            this.table_header[table_index] = "restore";
  
          } else {
            this.table_header.push("restore");
            this.fields.push({
              key: "restore",
            });
          }
  
          if (!this.can("restore-forum-license")) {
            let index = this.fields.findIndex((item) => item.key == "restore");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "restore"
              );
              this.table_header.splice(table_index, 1);
            }
          }
  
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (this.table_header.includes("restore")) {
            if (!this.can("edit-forum-license")) {
              this.fields[3] = {
                key: "delete",
              };
            } else {
              this.fields[4] = {
                key: "delete",
              };
            }
  
            let table_index = this.table_header.findIndex((item) => item == "restore");
            this.table_header[table_index] = "delete";
          }
  
          if (!this.can("edit-forum-license")) {
            let index = this.fields.findIndex((item) => item.key == "edit");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "edit"
              );
              this.table_header.splice(table_index, 1);
            }
          } else {
            if (!this.table_header.includes("edit")) {
              this.table_header.push("edit");
              this.fields[3] = {
                key: "edit",
              };
            }
          }
  
          if (!this.can("delete-forum-license")) {
            let index = this.fields.findIndex((item) => item.key == "delete");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "delete"
              );
              this.table_header.splice(table_index, 1);
            }
          } else {
            if (!this.table_header.includes("delete")) {
              this.table_header.push("delete");
              this.fields[4] = {
                key: "delete",
              };
            }
          }
        }
      },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
      "form.is_active"(v) {
        if (v == 1) {
            this.form.is_active = true;
        } else {
            this.form.is_active = false;
        }
      },
      activeTab(v) {
        if (v) this.tabActive();
      },
      options : {
        deep : true,
        handler(v){
          v.forEach((op) => {
            if(op.selected == true && this.selected.filter(s => s == op.key).length == 0){
              this.selected.push(op.key);
            }else if(op.selected == false){
              this.selected = this.selected.filter(s => op.key != s);
            }
          });
        }
      },
      selected : {
        deep : true,
        handler(){
          this.options.forEach((op,opi) => {
            if(op.values.length > 0){
              let c = this.selected.filter(s => op.values.map((item) => item.key).includes(s)).length;
              if(c == op.values.length || c !== 0){
                this.options[opi].selected = true;
              }else {
                this.options[opi].selected = false;
              }
            }                    
          });
        }
      }
    },
    mounted(){
        if (this.$route.name == "add-license" || this.$route.name == "edit-license") {
          this.fetchForumRoles();
          if(this.$route.name == "edit-license") {
            this.$nextTick(() => {
              this.editLicenses();
            }) 
          } 
          // this.options.map((item => item.values.splice(0,1)))
        } else {
          this.fields.map((item) => {
            this.table_header.push(item.key);
          });
          this.tabActive();
        }
    }
}