import {
    masterUrl,
    forum_tool,
  } from "../../js/path";
  
export default {
    data() {
      return {}
    },
    methods: {
        async getChannelList() {
            this.$store.commit("loader/updateStatus", true);
            let url = null;
            url = masterUrl.getChannelList;
            try {
                const data = await this.getRequest(url);
                if(typeof data.response == 'object'){
                    data.response.forEach((d) => {
                        this.optionsChannels.push({
                            value : Object.keys(d)[0],
                            text : Object.values(d)[0],
                        }); 
                    });
                }
            } catch(err) {
                console.log(err);
            }
            this.$store.commit("loader/updateStatus", false);
        },
        async fetchChannelData() {
            this.$store.commit("loader/updateStatus", true);
            let url = null;
            url = forum_tool.forumToolUrl + "/" + this.$route.params.id;
            const data = await this.getRequest(url);
            if (data.status) {
                if (data.response.channels != "" && data.response.channels != null) {
                    let channels = data.response.channels;
                    for (let key in channels) {
                        this.selectedChannels.push(key)
                    }
                }
            }
            this.$store.commit("loader/updateStatus", false);
        },
        async updateChannelData(id) {
            this.$store.commit("loader/updateStatus", true);
            let url = null;
            url = forum_tool.updateChannels + "/" + id;
            try {
                let dataAppend = new FormData();
                this.selectedChannels.forEach((c) =>
                    dataAppend.append("channels[]", c)
                );
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                    status: true,
                    icon: "success",
                    title: data.message,
                    });
                }
            } catch(err) {
                console.log(err);
                this.$store.commit("toast/updateStatus", {
                    status: true,
                    icon: "error",
                    title: err.data ? err.data.message : "Please try again!",
                });
            }
            this.$store.commit("loader/updateStatus", false);
        }
    },
    mounted() {
        this.getChannelList();
        this.fetchChannelData();
    }
}