import {
   brand_stage,
   stage
} from "../../js/path";

export default {
   data() {
      return {
         review_stage_response: [],
         brand_stage_response: [],
         form: {
            stage_id: [],
         }
      }
   },
   methods: {
      async fetchReviewStage() {
         try {
            const url = stage.stageUrl + "?nopagination";
            const data = await this.getRequest(url);
            if (data.status) {
               this.review_stage_response = data.response.data;
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Fetching error!"
            })
         }
      },
      async fetchBrandStage() {
         try {
            const url = brand_stage.brandStageUrl + "?brand_id=" + this.$route.params.forum_id;
            const data = await this.getRequest(url);
            if (data.status) {
               const responseData = data.response.data;
               responseData.map((item) => {
                  this.brand_stage_response.push({
                     review_stage_id: item.review_stage_id,
                     is_active: item.is_active,
                  })
                  if (item.is_active == 1) {
                     this.form.stage_id.push(item.review_stage_id);
                  }
               })
            }
         } catch (err) {
            console.log(err);
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Fetching error!"
            })
         }
      },
      async updateBrandStage() {
         try {
            const url = brand_stage.brandStageUrl + "?brand_id=" + this.$route.params.forum_id;
            let dataAppend = new FormData();
            this.review_stage_response.forEach(b => {
               dataAppend.append('stages[]', b.id);
               dataAppend.append('is_active[]', this.form.stage_id.includes(b.id) ? 1 : 0);
            });
            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message
               })
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Fetching error!"
            })
         }
      }
   },
   created() {
      this.fetchReviewStage();
      this.fetchBrandStage();
   }
}