import {
  forum_tool,
  forum,
} from "../../js/path";
//   import _ from 'lodash';

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      forumLists: [],
      fields: [{
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: 'forum_id',
          label: 'Forum Name',
          class: "text-center"
        },
        {
          key: "domain_name",
          label: "Domain Name",
          class: "text-center"
        },
        {
          key: "forum_logo",
          label: "Forum Logo",
          class: "text-center"
        },
        {
          key: 'forum_banner',
          label: 'Forum Banner Image',
          class: "text-center"
        },
        {
          key: "field_rep_channels",
          label: "Field Rep Channels",
          class: "text-center"
        },
        {
          key: "settings",
          class: "text-center"
        },
        {
          key: "edit",
          class: "text-center"
        },
      ],
      form: {
        forum_id: "",
        domain_name: "",
        field_rep_channels: []
      },
      logo_image: "",
      banner_image: "",
      edit: {
        logo_image: "",
        banner_image: "",
      },
      getFieldRepChannel: [],
      tableData: [],
      activeTab: "all",
      key: 0,
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
    };
  },
  methods: {
    getFieldRepsChannels(channel) {
        let channel_value = Object.keys(channel)[0];
        return channel[channel_value];
    },
    settings(id, forum_id) {
      this.$router.push("/forum-settings/" + id + "/" + forum_id);
    },
    stateStore(id, forum_id) {
      this.$store.commit("setData", {
        forum_id: forum_id,
        forum_tool_id: id
      });
      this.$router.push("/forum-settings/" + id + "/" + forum_id)
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData() {
      let url = null;
      this.$store.commit("loader/updateStatus", true);
      url = forum_tool.forumToolUrl
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    readFile(e, txt) {
      if (txt == "logo_image") {
        this.logo_image = e.target.files[0];
        if (this.logo_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB"
          });
          this.$refs.logo_image.reset();
          this.logo_image = '';
          return false;
        }
        return true;
      } else if (txt == "banner_image") {
        this.banner_image = e.target.files[0];
        if (this.banner_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB"
          });
          this.$refs.banner_image.reset();
          this.banner_image = '';
          return false;
        }
        return true;
      }
    },
    async fetchForumTool(id, forum_id) {
      this.$store.commit("loader/updateStatus", true);
      let url = null;
      if (this.$route.name == "edit-forum-tool") {
        url = forum_tool.forumToolUrl + "/" + id;
      } else {
        url = forum_tool.forumToolUrl + "/" + id + "/" + forum_id;
      }
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response;
        if (responseData.forum_id != "" && responseData.forum_id != null) {
          this.form.forum_id = responseData.forum_id;
        }
        if (responseData.domain_name != "" && responseData.domain_name != null) {
          this.form.domain_name = responseData.domain_name;
        }
        if (responseData.logo_image != "" && responseData.logo_image != null) {
          this.form.logo_image = responseData.logo_image;
        }
        if (responseData.banner_image != "" && responseData.banner_image != null) {
          this.form.banner_image = responseData.banner_image;
        }
        this.edit.logo_image = responseData.logo_image;
        this.edit.banner_image = responseData.banner_image;
        if (responseData.field_rep_channels != null && responseData.field_rep_channels != '') {
          responseData.field_rep_channels.forEach((value) => {
            let key1 = Object.keys(value)[0];
            let element = {
              "text" : value[key1],
              "value" : key1,
            }
            this.form.field_rep_channels.push(element);
          });
        }
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchForum() {
      const url = forum.fetchAllActiveForum;
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response;
        this.forumLists = responseData.data;
      }
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        let url = forum_tool.forumToolUrl;
        let dataAppend = new FormData();
        if (this.logo_image) {
          dataAppend.append("logo_image", this.logo_image);
        }
        if (this.banner_image) {
          dataAppend.append("banner_image", this.banner_image);
        }

        if (this.form.field_rep_channels != null && this.form.field_rep_channels != '') {
          let element_array = [];
          this.form.field_rep_channels.forEach((element) => {
            let temp1 = {};
            temp1[element.value] = element.text;
            element_array.push(temp1);
          });
          dataAppend.append('field_rep_channels', JSON.stringify(element_array));
        }

        for (var key in this.form) {
          if (key != 'field_rep_channels') {
            dataAppend.append(key, this.form[key]);
          }
        }

        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/forum-tool");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async updateData(id) {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        let url = forum_tool.forumToolUrl + "/" + id;
        let dataAppend = new FormData();
        dataAppend.append("forum_id", this.form.forum_id);
        dataAppend.append("domain_name", this.form.domain_name);
        dataAppend.append("logo_image", this.logo_image);
        dataAppend.append("banner_image", this.banner_image);
        if (this.form.field_rep_channels != null && this.form.field_rep_channels != '') {
          let element_array = [];
          this.form.field_rep_channels.forEach((element) => {
            let temp1 = {};
            temp1[element.value] = element.text;
            element_array.push(temp1);
          });
          dataAppend.append('field_rep_channels', JSON.stringify(element_array));
        }
        dataAppend.append("_method", "put");
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/forum-tool");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchfieldRepChannel() {
      try {
        const url = forum_tool.fetchFieldRepChannel;
        const data = await this.getRequest(url);
        if (data.status) {
          if (typeof data.response == 'object'){
            data.response.forEach((d) => {
                this.getFieldRepChannel.push({
                    value : Object.keys(d)[0],
                    text : Object.values(d)[0],
                }); 
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  mounted() {
    if (this.$route.name == "add-forum-tool" || this.$route.name == "edit-forum-tool") {
      this.fetchForum();
      this.fetchfieldRepChannel();
      if (this.$route.name == "edit-forum-tool") {
        this.fetchForumTool(this.$route.params.id);
      }
      if (this.$route.name == "settings") {
        this.fetchForumTool(this.$route.params.id, this.$route.params.forum_id);
      }
    } else {
      this.activeTab = "all";
      if (this.$route.query.filter) {
        this.activeTab = this.$route.query.filter;
      }
      if (this.$route.query.page && this.$route.query.page != 1) {
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.fetchData(this.activeTab);
      }
    }
  },
  computed: {
    transformedOptions() {
      return this.getFieldRepChannel.map(item => {
        if (item.value === 'sms') {
          return {
            ...item, text: item.text.toUpperCase()
          };
        }
       return item;
      })
    }
  }
};