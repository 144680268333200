<template>
    <b-tab v-if="can('forum-vendor')" title="Vendor List" active>
        <div id="forum" class="row align-items-center mb-4" >
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
                <div class="all-tabs">
                    <div :class="activeTab == 'all' ? ' tab-options' : ''" @click.prevent="fetchData('all')"
                    class="mx-2 opt">
                    All
                    </div>
                    |
                    <div :class="activeTab == 'trash' ? ' tab-options' : ''" @click.prevent="fetchData('trash')"
                    class="mx-2 opt">
                    Trash
                    </div>
                    <template v-if="can('add-forum-vendor')">
                    |
                    <div class="d-flex align-items-center">
                        <svg class="svg-inline--fa fa-table mx-2" height="16px" width="16px" aria-hidden="true"
                        focusable="false" data-prefix="fa" data-icon="table" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                        <path fill="currentColor"
                            d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z">
                        </path>
                        </svg>
                        <router-link :to="{ name: 'add-vendor', params: { forum_id: $route.params.forum_id }  }" class="opt">Add </router-link>
                        <!-- <div @click="addVendors">Add</div> -->
                    </div>
                    </template>
                </div>
            </div>
        </div>
        <b-table 
            striped hover
            :items="tableData.data"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered">
            <template v-slot:cell(edit)="row">
                <router-link :to="{ name: 'edit-vendor', params: { id: row.item.id, forum_id: row.item.forum_id } }">
                    <i class="fa fa-edit edit-i"></i>
                </router-link>
            </template>
            <template v-if="fields[fields.length - 1].key == 'delete'" v-slot:cell(delete)="row"
                v-can="'delete-forum-vendor'">
                <div v-if="activeTab != 'trash'" @click.prevent="deleteVendor(row.item.id)">
                <i class="mdi mdi-trash-can delete-i"></i>
                </div>
            </template>
            <template v-else v-slot:cell(restore)="row" v-can="'restore-forum-vendor'">
                <div v-if="activeTab == 'trash'" @click.prevent="resotreVendor(row.item.id)">
                <i class="mdi mdi-restore restore-i"></i>
                </div>
            </template>
            <template v-slot:cell(api_secret)="row">
                <span style="word-break: break-all;">{{ row.item.api_secret }}</span>
            </template>
            <template v-slot:cell(api_url)="row">
                <span style="word-break: break-all;">{{ row.item.api_url }}</span>
            </template>
            <!-- <template v-slot:cell(delete)="row">
                <div v-if="activeTab == 'all'" @click.prevent="deleteVendor(row.item.id)">
                    <i class="mdi mdi-trash-can delete-i"></i>
                </div>
            </template>
            <template v-slot:cell(restore)="row">
                <div v-if="activeTab == 'trash'" @click.prevent="resotreVendor(row.item.id)">
                    <i class="mdi mdi-restore restore-i"></i>
                </div>
            </template> -->
        </b-table>
        <div class="row">
            <div class="col">
                <div class="dataTables_pa ginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="tableData.total"
                        :per-page="perPage"
                    ></b-pagination>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <add-vendor :edit="edit" :formData="form" v-if="openmodal" @closeVendors="closeVendors"></add-vendor> -->
    </b-tab>
</template>
<script>
import MixinRequest from "../../../../mixins/request";
import forumToolVendorMixin from "../../../../mixins/ModuleJs/forum-vendor";

export default {
    mixins: [MixinRequest, forumToolVendorMixin],
    data() {
        return {
            tableData: [],
            fields: [
                { key: "id", sortable: true, label: "ID" },
                { key: "vendor_name", sortable: true, label: "Vendor Name" },
                { key: "channel_type", sortable: true, label: "Channel Type" },
                { key: "api_url", sortable: true, label: "API Url" },
                { key: "api_key", sortable: true, label: "API Key" },
                { key: "api_secret", sortable: true, label: "API Secret" },
                { key: "edit", sortable: true, label: "Edit" },
                { key: "delete", sortable: true, label: "Delete" },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 25, 50],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: false,
        };
    },
    components: {
    },
};
</script>